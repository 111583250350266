.org-ird {
  background: no-repeat center url('../img/organizations/ird.png');
}

.org-ipc {
  background: no-repeat center url('../img/organizations/ipc.png');
}

.org-ipm {
  background: no-repeat center url('../img/organizations/ipm.png');
}

.org-espacedev {
  background: no-repeat center url('../img/organizations/espacedev.png');
}

.org-mivegec {
  background: no-repeat center url('../img/organizations/mivegec.png');
}

.org-smru {
  background: no-repeat center url('../img/organizations/smru.png');
}

.org-pivot {
  background: no-repeat center url('../img/organizations/pivot.png');
}

.org-seasoi {
  background: no-repeat center url('../img/organizations/seasoi.png');
}

.org-ur {
  background: no-repeat center url('../img/organizations/ur.png');
}